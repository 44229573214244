import { combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import authSlice from './features/network/authSlice'
import salesDateSlice from './features/network/salesDateSlice'
import cameraSlice from './features/camera/cameraSlice'
import uploadImageSlice from './features/network/uploadImageSlice'
import formSlice from './features/network/formsSlice'
import messageSlice from './features/network/messageSlice'
import consistencySlice from './features/network/consistencySlice'
import consistencyResultSlice from './features/network/consistencyResultSlice'
import expireSlice from './features/expire/expireSlice'
import activeSlice from './features/network/activeSlice'
import submitSlice from './features/network/submitSlice'
import modeSlice from './features/mode/modeSlice'
import registerSlice from './features/register/registerSlice'
import tenantUserSlice from './features/network/tenantUserSlice'

const rootPersistConfig = {
  key: 'app',
  version: 1,
  storage,
  blacklist: ['auth', 'salesDate', 'camera', 'inputData', 'consistencyResult'],
}

const basePersistConfig = {
  storage,
  blacklist: ['status', 'error', 'loading'],
}

const authPersistConfig = {
  key: 'auth',
  ...basePersistConfig,
}

const tenantUserPersistConfig = {
  key: 'tenantUser',
  ...basePersistConfig,
}

const salesDatePersistConfig = {
  key: 'salesDate',
  ...basePersistConfig,
}
const cameraPersistConfig = {
  key: 'camera',
  ...basePersistConfig,
}

const inputDataPersistConfig = {
  key: 'inputData',
  ...basePersistConfig,
}

const messagePersistConfig = {
  key: 'message',
  ...basePersistConfig,
}

const expirePersistConfig = {
  key: 'expire',
  storage,
}

const modePersistConfig = {
  key: 'mode',
  storage,
}

const registerPersistConfig = {
  key: 'register',
  storage,
}

const rootReducer = combineReducers({
  active: activeSlice.reducer,
  submit: submitSlice.reducer,
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  tenantUser: persistReducer(tenantUserPersistConfig, tenantUserSlice.reducer),
  salesDate: persistReducer(salesDatePersistConfig, salesDateSlice.reducer),
  camera: persistReducer(cameraPersistConfig, cameraSlice.reducer),
  uploadImage: uploadImageSlice.reducer,
  inputData: persistReducer(inputDataPersistConfig, formSlice.reducer),
  consistency: consistencySlice.reducer,
  message: persistReducer(messagePersistConfig, messageSlice.reducer),
  consistencyResult: consistencyResultSlice.reducer,
  expire: persistReducer(expirePersistConfig, expireSlice.reducer),
  mode: persistReducer(modePersistConfig, modeSlice.reducer),
  register: persistReducer(registerPersistConfig, registerSlice.reducer),
})

// export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
export const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
