import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Div100vh from '../../components/atoms/Div100vh'
import Space from '../../components/atoms/Space'
import Typography from '../../components/atoms/Typography'
import LoadingModal from '../../components/organisms/LoadingModal'
import styles from './styles.module.scss'
import { fixButtonTitle, sendButtonTitle, title } from './messages'
import { RootState } from '../../store'
import {
  EmailSendCompletionPath,
  inputEmailPath,
  topWithClearDataPath,
} from '../../routes/paths'
import useAppHistory from '../../utils/useAppHistory'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import { clearApiRequest } from '../../features/network/formsSlice'
import {
  tenantUserSignUpParam,
  tenantUserSignUp,
} from '../../features/network/tenantUserSlice'

function InputEmailConfirm(): ReactElement {
  const { email, error, apiStatus } = useSelector(
    (state: RootState) => state.tenantUser
  )

  const history = useAppHistory()
  const dispatch = useDispatch()

  const handleFixButtonClick = (): void => {
    history.push(inputEmailPath)
  }

  const handleButtonClick = async (): Promise<void> => {
    // NOTE: ユーザーは大文字を入力できるが、送る前に小文字に変換して扱う
    const trimAndLoweredEmail = email.trim().toLowerCase()
    const param: tenantUserSignUpParam = {
      email: trimAndLoweredEmail,
    }
    const actionResult = await dispatch(tenantUserSignUp(param))
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      tenantUserSignUp.fulfilled.match(actionResult)
    ) {
      history.push(EmailSendCompletionPath)
    }
  }

  const handleErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearApiRequest())
  }

  return (
    <Div100vh className={styles.root} disabledModeWrapper>
      <div className={styles.container}>
        <Typography className={styles.title}>{title}</Typography>
        <Space size="52px" orientation="vertical" />
        <div className={styles.content}>
          <Typography>{email}</Typography>
        </div>
        <Space size="144px" orientation="vertical" />
        <div className={styles.buttons}>
          <Button
            variant="contained"
            color="white"
            onClick={handleFixButtonClick}
          >
            {fixButtonTitle}
          </Button>
          <Button variant="contained" color="green" onClick={handleButtonClick}>
            {sendButtonTitle}
          </Button>
        </div>
      </div>
      {apiStatus === 'Progress' && (
        <LoadingModal isOpen className={styles.loadingModal} />
      )}
      {error && (
        <ErrorModal
          isOpen
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorClick(error)}
        />
      )}
    </Div100vh>
  )
}

export default InputEmailConfirm
